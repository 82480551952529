import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import mapboxgl, {MapMouseEvent} from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import styled from 'styled-components';
import {AnimatePresence} from 'framer-motion';

import routes from 'config/routes/rewards';
import {NATIVE_MESSAGES} from 'constants/native-events';
import {LocationContext} from 'contexts/LocationContext';
import {CACHE_KEYS} from 'constants/cache-keys';
import {SettingsContext} from 'contexts/SettingsContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {sendMessageToNativeApp} from 'services/native-api';
import {getFeatures, getVouchers, searchMap, getMapFilters} from 'services/vouchers';
import {PurchasedVoucher, Voucher} from 'types/Offers';
import {getOptimizedDistance} from 'utils/numbers';
import {calculateDistance} from 'utils/location';
import {noop} from 'utils/functions';

import Loader from 'components/Loader/Loader';
import VoucherCard from 'components/Rewards/Vouchers/VoucherCard/VoucherCard';
import MapInfo from 'components/MapInfo/MapInfo';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {useLocation} from 'react-router-dom';

import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import RadioButton from 'components/Form/RadioButton';

mapboxgl.accessToken =
  'pk.eyJ1Ijoia2xpbWEtdGFsZXIiLCJhIjoiY2xrYXZ2YWdkMGM1NzNtcWQ5MHFqbHVlMiJ9.rzkcS6tScdgN9PkYZBtsXg';

enum EntityType {
  CLIMATE_PARTNER = 'climate-partner',
}

type MapFeature = {
  type: string;
  state: {};
  geometry: {
    type: string;
    coordinates: Array<number>;
  };
  properties: {
    city?: string;
    id?: string;
    address?: string;
    title?: string;
    description?: string;
    url?: string;
    postal_code?: string;
    image?: string;
    layer?: string;
    icon_active?: string;
    icon_inactive?: string;
    entity_type?: EntityType;
    entity_id?: string;
  };
  id: number;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .wf {
    &__container {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow-y: hidden;
    }

    &__fountain {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;
      border-radius: 10px 10px 0 0;
    }

    &__fountain {
    }
  }
`;

const VouchersList = styled.div`
  max-height: 330px;
  overflow-y: auto;
`;

const VoucherCardContent = styled.div`
  margin: 0 auto;
  position: relative;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 0 20px 20px;

  .offers-filter-list {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &__text {
      margin-left: 15px;
    }
  }
`;

const DEFAULT_LOCATION = {
  longitude: 9.4069,
  latitude: 49.7897,
};

// Define the type for a filter item
type FilterItem = {
  id: string;
  name: string;
  checked: boolean;
  type: 'poi' | 'partners';
};

const FilterItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

const FilterLabel = styled.span`
  margin-left: 8px;
`;

const FilterItemComponent = React.memo(
  ({
    filter,
    onToggle,
  }: {
    filter: FilterItem;
    onToggle: (id: string) => void;
  }) => (
    <FilterItemWrapper onClick={() => onToggle(filter.id)}>
      <RadioButton checked={filter.checked} />
      <FilterLabel>{filter.name}</FilterLabel>
    </FilterItemWrapper>
  ),
);

interface MapProps {
  showFilters: boolean;
  onFiltersOpen: () => void;
  onFiltersClose: () => void;
}

const Map = (props: MapProps) => {
  const {showFilters, onFiltersOpen, onFiltersClose} = props;
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map>();
  const {search} = useLocation();
  const {location, updateCurrentLocation} = useContext(LocationContext);
  const {organizationConfig} = useContext(SettingsContext);
  const {translate} = useTranslations();

  const [latLng, setLatLng] = useState({
    lng: location?.longitude || DEFAULT_LOCATION.longitude,
    lat: location?.latitude || DEFAULT_LOCATION.latitude,
  });
  const [zoom, setZoom] = useState(12.5);
  const [feature, setFeature] = useState<MapFeature>();
  const [vouchers, setVouchers] = useState<PurchasedVoucher[]>([]);

  const {
    isLoading: isLoadingVouchers,
    fetchDataWithKey,
    data: vouchersData,
  } = useFetcher<Voucher[]>({
    fetcher: getVouchers,
    initialValue: [],
    preventFetch: true,
  });

  useEffect(() => {
    updateCurrentLocation();
  }, [updateCurrentLocation]);

  useEffect(() => {
    if (!location) {
      return;
    }

    const currentLatLng = {
      lng: location.longitude,
      lat: location.latitude,
    };
    setLatLng(currentLatLng);

    if (mapRef.current) {
      mapRef.current?.setCenter(currentLatLng);
    }
  }, [location]);

  useEffect(() => {
    if (!mapContainerRef.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/klima-taler/clv28jrci00aq01qpchync4vz',
      center: [latLng.lng, latLng.lat],
      zoom,
    });

    mapRef.current = map;

    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false,
        enableGeolocation: false,
        collapsed: true,
        externalGeocoder: async (query) => {
          console.log('searching features', query);

          var features = await searchMap(query);

          console.log('search results', features);

          return features;
        },
      }),
    );

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    let geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });

    map.addControl(geolocate);

    map.on('load', async function () {
      console.log('Map loaded, adding new layers..');

      map.addSource('poi-pending', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      map.addSource('partners-pending', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      map.addLayer({
        id: 'poi-pending',
        type: 'symbol',
        paint: {},
        layout: {
          'icon-image': ['to-string', ['get', 'icon_inactive']],
        },
        source: 'poi-pending',
      });

      map.addLayer({
        id: 'partners-pending',
        type: 'symbol',
        paint: {},
        layout: {
          'icon-image': ['to-string', ['get', 'icon_inactive']],
        },
        source: 'partners-pending',
      });

      console.log('New layers added, triggering geolocate..');

      geolocate.trigger();

      const searchParams = new URLSearchParams(search);

      if (searchParams.get('source') === 'offers') {
        console.log('Disabling POI layers..');

        map.setLayoutProperty('poi', 'visibility', 'none');
        map.setLayoutProperty('poi-pending', 'visibility', 'none');
      }

      console.log('Fetching features from backend...');

      let features = await getFeatures();

      console.log('Got features from backend', features);

      // @ts-ignore
      map.getSource('poi-pending').setData(features.poi);
      // @ts-ignore
      map.getSource('partners-pending').setData(features.partners);

      console.log('Updated map sources, all done!');
    });

    map.on('move', () => {
      // setLatLng({lat, lng});
      setZoom(map.getZoom());
    });

    map.on('click', (event: MapMouseEvent) => {
      const features = map.queryRenderedFeatures(event.point, {
        layers: ['poi', 'partners', 'partners-pending', 'poi-pending'],
      });

      console.log('feature selected', features);

      if (!features.length) {
        return;
      }

      const feature = features[0] as unknown as MapFeature;

      if (!feature) {
        return;
      }

      setFeature(feature);
      setLatLng(event.lngLat);
    });

    class FilterControl {
      private _container?: HTMLDivElement;

      onAdd(map: any) {
        let icon1 = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-1 -1 20 20"><defs><clipPath id="clip-path"><rect id="Rectangle_7292" data-name="Rectangle 7292" width="18" height="18" transform="translate(0 0)" fill="none"/></clipPath></defs><g id="Group_22586" data-name="Group 22586" transform="translate(0 0) scale(0.9)"><path id="Path_43748" data-name="Path 43748" d="M17.394,0H.606A.606.606,0,0,0,0,.606V4.463a.606.606,0,0,0,.195.445l5.792,5.362v7.125a.606.606,0,0,0,.909.525l4.814-2.77a.608.608,0,0,0,.3-.526V10.269l5.793-5.362A.606.606,0,0,0,18,4.463V.606A.606.606,0,0,0,17.394,0m-.606,2.88H1.212V1.212H16.788ZM11,9.56A.606.606,0,0,0,10.8,10v4.268L7.2,16.346V10a.606.606,0,0,0-.194-.445L1.212,4.2V4.092H16.788V4.2Z" transform="translate(0 0)" /></g></svg>`;

        const div = document.createElement('div');

        div.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

        div.innerHTML = `<button>${icon1}</button>`;

        div.addEventListener('contextmenu', (e) => e.preventDefault());

        div.addEventListener('click', () => {
          onFiltersOpen();
        });

        this._container = div; // Store the container for later use in onRemove

        return div;
      }

      onRemove() {
        // Clean up any resources or event listeners
        if (this._container && this._container.parentNode) {
          this._container.parentNode.removeChild(this._container);
        }
      }
    }

    const searchParams = new URLSearchParams(search);

    if (searchParams.get('source') !== 'offers') {
      const filterControl: any = new FilterControl();

      //map.addControl(filterControl, 'top-right');
    }

    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVouchers([]);
    if (feature?.properties?.entity_type !== EntityType.CLIMATE_PARTNER) {
      return;
    }

    const id = feature.properties.entity_id;
    const key = `${CACHE_KEYS.CLIMATE_PARTNER_OFFERS}/${id}`;

    fetchDataWithKey({
      key,
      newParams: {climate_partner_id: id},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature]);

  useEffect(() => {
    setVouchers(
      vouchersData.map((offer) => ({
        id: offer.id,
        offer,
      })),
    );
  }, [vouchersData]);

  const treasure = useMemo(() => {
    if (!feature) {
      return;
    }

    const {title, description, address, city, postal_code, image} =
      feature.properties;
    let optimizedDistance;
    if (location) {
      const distance = calculateDistance(location, {
        longitude: latLng.lng,
        latitude: latLng.lat,
      });
      optimizedDistance = getOptimizedDistance({
        distance,
        optimizeDistance: true,
      });
    }

    return {
      title,
      description,
      image,
      address: [address, city, postal_code].filter((a) => a).join(', '),
      location: optimizedDistance,
    };
  }, [feature, location, latLng]);

  const handleDirectionRequest = useCallback(() => {
    if (!latLng || !treasure) {
      return;
    }

    const message = {
      type: NATIVE_MESSAGES.REQUEST_GIVE_DIRECTION,
      coordinates: {
        longitude: latLng.lng,
        latitude: latLng.lat,
      },
      formatted_address: treasure.address,
    };
    sendMessageToNativeApp({message});
  }, [treasure, latLng]);

  // Define the default filters for POI and Partners
  // var defaultPoiFilters: FilterItem[] = [
  //   { id: 'all', name: 'All', checked: true, type: 'poi' },
  //   { id: 'offers', name: 'Offers', checked: false, type: 'poi' },
  //   { id: 'ebike-charging-station', name: 'Ebike Charging Station', checked: false, type: 'poi' },
  //   { id: 'water-fountain', name: 'Water Fountain', checked: false, type: 'poi' },
  //   { id: 'bicycle-repair', name: 'Bicycle Repair', checked: false, type: 'poi' },
  //   { id: 'bicycle-storage', name: 'Bicycle Storage', checked: false, type: 'poi' },
  //   { id: 'faircup', name: 'Faircup', checked: false, type: 'poi' },
  //   { id: 'ecar-charging', name: 'Ecar Charging', checked: false, type: 'poi' },
  //   { id: 'park-ride', name: 'Park Ride', checked: false, type: 'poi' },
  //   { id: 'park-carpooling', name: 'Park Carpooling', checked: false, type: 'poi' },
  //   { id: 'sustainable-consumption', name: 'Sustainable Consumption', checked: false, type: 'poi' },
  //   { id: 'bike-rental', name: 'Bike Rental', checked: false, type: 'poi' },
  //   { id: 'cargobike-rental', name: 'Cargobike Rental', checked: false, type: 'poi' },
  //   { id: 'car-sharing', name: 'Car Sharing', checked: false, type: 'poi' },
  //   { id: 'bus-departure', name: 'Bus Departure', checked: false, type: 'poi' },
  //   { id: 'clothing-container', name: 'Clothing Container', checked: false, type: 'poi' },
  //   { id: 'recycling-center', name: 'Recycling Center', checked: false, type: 'poi' },
  //   { id: 'cool-places', name: 'Cool Places', checked: false, type: 'poi' },
  //   { id: 'repair-cafe', name: 'Repair Cafe', checked: false, type: 'poi' },
  //   { id: 'public-bookcase', name: 'Public Bookcase', checked: false, type: 'poi' },
  //   { id: 'food-saving', name: 'Food Saving', checked: false, type: 'poi' },
  //   { id: 'eatable-city', name: 'Eatable City', checked: false, type: 'poi' }
  // ];

  // let defaultPartnerFilters: FilterItem[] = [
  //   { id: 'all', name: 'All', checked: true, type: 'partners' },
  //   { id: 'apparel', name: 'Apparel', checked: false, type: 'partners' },
  //   { id: 'bakery', name: 'Bakery', checked: false, type: 'partners' },
  //   { id: 'bar', name: 'Bar', checked: false, type: 'partners' },
  //   { id: 'bicycle', name: 'Bicycle Shop', checked: false, type: 'partners' },
  //   { id: 'book-store', name: 'Book store', checked: false, type: 'partners' },
  //   { id: 'cafe', name: 'Café', checked: false, type: 'partners' },
  //   { id: 'cinema', name: 'Cinema', checked: false, type: 'partners' },
  //   { id: 'culture', name: 'Culture', checked: false, type: 'partners' },
  //   { id: 'cosmetics', name: 'Cosmetics', checked: false, type: 'partners' },
  //   { id: 'e-charging', name: 'E-Charging', checked: false, type: 'partners' },
  //   { id: 'electronic', name: 'Electronic', checked: false, type: 'partners' },
  //   { id: 'grocery', name: 'Groceries', checked: false, type: 'partners' },
  //   { id: 'gym', name: 'Gym', checked: false, type: 'partners' },
  //   { id: 'hairdresser', name: 'Hairdresser', checked: false, type: 'partners' },
  //   { id: 'health', name: 'Health', checked: false, type: 'partners' },
  //   { id: 'home-garden', name: 'Home & Garden', checked: false, type: 'partners' },
  //   { id: 'home-appliances', name: 'Home Appliances', checked: false, type: 'partners' },
  //   { id: 'hotel', name: 'Hotels', checked: false, type: 'partners' },
  //   { id: 'houseware', name: 'Housewares', checked: false, type: 'partners' },
  //   { id: 'jewelry', name: 'Jewelry', checked: false, type: 'partners' },
  //   { id: 'mobility', name: 'Mobility', checked: false, type: 'partners' },
  //   { id: 'museum', name: 'Museum', checked: false, type: 'partners' },
  //   { id: 'outdoor', name: 'Outdoor', checked: false, type: 'partners' },
  //   { id: 'pharmacy', name: 'Pharmacy', checked: false, type: 'partners' },
  //   { id: 'photo', name: 'Photo', checked: false, type: 'partners' },
  //   { id: 'restaurant', name: 'Restaurant', checked: false, type: 'partners' },
  //   { id: 'shoes', name: 'Shoes', checked: false, type: 'partners' },
  //   { id: 'sight', name: 'Sight', checked: false, type: 'partners' },
  //   { id: 'sports', name: 'Sports equipment', checked: false, type: 'partners' },
  //   { id: 'sports-event', name: 'Sport event', checked: false, type: 'partners' },
  //   { id: 'stationery', name: 'Stationery', checked: false, type: 'partners' },
  //   { id: 'swimming-pool', name: 'Swimming pool', checked: false, type: 'partners' },
  //   { id: 'toys', name: 'Toys', checked: false, type: 'partners' },
  //   { id: 'wellness', name: 'Wellness', checked: false, type: 'partners' },
  //   { id: 'yoga-studio', name: 'Yoga Studio', checked: false, type: 'partners' },
  //   { id: 'optician', name: 'Optician', checked: false, type: 'partners' },
  //   { id: 'fallback', name: 'Other', checked: false, type: 'partners' }
  // ];

  const [defaultPoiFilters, setDefaultPoiFilters] = useState<FilterItem[]>([]);
  const [defaultPartnerFilters, setDefaultPartnerFilters] = useState<FilterItem[]>([]);

  const [poiFilters, setPoiFilters] = useState<FilterItem[]>(defaultPoiFilters);
  const [partnerFilters, setPartnerFilters] = useState<FilterItem[]>(defaultPartnerFilters);

  const [currentPage, setCurrentPage] = useState<'map' | 'offers'>('map');

  useEffect(() => {  
    const fetchFilters = async () => {
      const filters = await getMapFilters();
      console.log('filters', filters);

      setDefaultPoiFilters(filters.poi);
      setPoiFilters(filters.poi);

      setDefaultPartnerFilters(filters.partners);
      setPartnerFilters(filters.partners);
    };

    fetchFilters();

    const searchParams = new URLSearchParams(search);

    if (searchParams.get('source') === 'offers') {
      setCurrentPage('offers');
    }
  }, []);

  const togglePoiFilter = useCallback((id: string) => {
    setPoiFilters((prevFilters) => {
      let updatedFilters;

      if (id === 'all') {
        updatedFilters = defaultPoiFilters.map(filter => ({ ...filter, checked: filter.id === 'all' }));
      } else {
        updatedFilters = prevFilters.map((filter) =>
          filter.id === id
            ? { ...filter, checked: !filter.checked }
            : filter.id === 'all'
            ? { ...filter, checked: false }
            : filter
        );

        const allItemsChecked = updatedFilters.every(filter => filter.id !== 'all' && filter.checked);

        if (allItemsChecked) {
          updatedFilters = defaultPoiFilters.map(filter => ({ ...filter, checked: filter.id === 'all' }));
        }
      }

      if (!mapRef.current) return prevFilters;

      const map = mapRef.current;
      const allFilter = updatedFilters.find(filter => filter.id === 'all')?.checked;
      const activeFilters = updatedFilters.filter(filter => filter.checked && filter.id !== 'all');

      if (allFilter) {
        map.setFilter('poi', null);
        map.setFilter('poi-pending', null);
      } else {
        const poiFilter = ['in', 'type', ...activeFilters.map(filter => filter.id)];
        map.setFilter('poi', poiFilter);
        map.setFilter('poi-pending', poiFilter);
      }

      const offersFilter = updatedFilters.find(filter => filter.id === 'offers')?.checked;

      if (offersFilter || allFilter) {
        map.setLayoutProperty('partners', 'visibility', 'visible');
        map.setLayoutProperty('partners-pending', 'visibility', 'visible');
      } else {
        map.setLayoutProperty('partners', 'visibility', 'none');
        map.setLayoutProperty('partners-pending', 'visibility', 'none');
      }

      return updatedFilters;
    });
  }, [defaultPoiFilters]);

  const togglePartnerFilter = useCallback((id: string) => {
    setPartnerFilters((prevFilters) => {
      let updatedFilters;

      if (id === 'all') {
        updatedFilters = defaultPartnerFilters.map(filter => ({ ...filter, checked: filter.id === 'all' }));
      } else {
        updatedFilters = prevFilters.map((filter) =>
          filter.id === id
            ? { ...filter, checked: !filter.checked }
            : filter.id === 'all'
            ? { ...filter, checked: false }
            : filter
        );

        const allItemsChecked = updatedFilters.every(filter => filter.id !== 'all' && filter.checked);

        if (allItemsChecked) {
          updatedFilters = defaultPartnerFilters.map(filter => ({ ...filter, checked: filter.id === 'all' }));
        }
      }

      if (!mapRef.current) return prevFilters;

      const map = mapRef.current;
      const allFilter = updatedFilters.find(filter => filter.id === 'all')?.checked;
      const activeFilters = updatedFilters.filter(filter => filter.checked && filter.id !== 'all');

      if (allFilter) {
        map.setFilter('partners', null);
        map.setFilter('partners-pending', null);
      } else {
        const partnerFilter = ['in', 'type', ...activeFilters.map(filter => filter.id)];
        map.setFilter('partners', partnerFilter);
        map.setFilter('partners-pending', partnerFilter);
      }

      return updatedFilters;
    });
  }, [defaultPartnerFilters]);

  return (
    <Wrapper className="wf">
      <div className="wf__container" ref={mapContainerRef} />
      <AnimatePresence>
        {treasure && (
          <MapInfo
            name={<b>{treasure.title}</b>}
            description={treasure.description}
            address={treasure.address}
            location={treasure.location}
            image={treasure.image}
            onDirectionRequest={handleDirectionRequest}
            styles={vouchers.length === 0 ? {bgColor: '#FFFFFF'} : {}}
            onClose={() => setFeature(undefined)}>
            {feature?.properties?.entity_type ===
              EntityType.CLIMATE_PARTNER && (
              <AnimatePresence>
                <VouchersList>
                  {isLoadingVouchers ? (
                    <LoaderWrapper>
                      <Loader color="#222" sm />
                    </LoaderWrapper>
                  ) : vouchers.length > 0 ? (
                    vouchers.map((voucher) => (
                      <VoucherCardContent key={voucher.id}>
                        <VoucherCard
                          bgColor={
                            organizationConfig.theme.components
                              .climatePartnerMap?.voucherBgColor
                          }
                          voucher={voucher}
                          voucherUrl={`${routes.REWARDS.DEALS.href}/vouchers`}
                          isDeleteActive={false}
                          showClimateIcon={true}
                          hideLocation={true}
                          toggleDeleteActions={noop}
                          removeVoucher={{title: '', desc: '', delete: noop}}
                          reloadVouchers={noop}
                        />
                      </VoucherCardContent>
                    ))
                  ) : (
                    <div className="text-center">
                      <b>{translate('sdk.web.treasure.map.no.offers')}</b>
                    </div>
                  )}
                </VouchersList>
              </AnimatePresence>
            )}
          </MapInfo>
        )}
      </AnimatePresence>

      <PromptWrapper
        isVisible={showFilters}
        onClose={() => onFiltersClose()}
        title={translate('sdk.web.web.treasure.map.filter.title')}
        styles={{pb: '0'}}>
 
          {currentPage === 'map' && (
            <ListWrapper className="offers-filter-list">
              {poiFilters.map((filter) => (
                <FilterItemComponent key={filter.id} filter={filter} onToggle={togglePoiFilter} />
              ))}
            </ListWrapper>
          )}

          {currentPage === 'offers' && (
            <ListWrapper className="offers-filter-list">
              {partnerFilters.map((filter) => (
              <FilterItemComponent key={filter.id} filter={filter} onToggle={togglePartnerFilter} />
              ))}
            </ListWrapper>
          )}

      </PromptWrapper>
    </Wrapper>
  );
};

export default Map;
